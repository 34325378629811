import React from 'react';
import './App.css';
import Main from './Components/Main'
import Code from './Components/Code'
import Background from './Assets/semi_cirlce.png'

function App() {
  return (
    <section className="hero-wrap">
      <div className="navbar-brand" href="index.html">
        <span>A</span>aditya
      </div>
      <div className="overlay"></div>
      <Code />
      {/* <Main /> */}
    </section>
  );
}

export default App;
