import React, {useState, useEffect} from 'react';
import './Code.css'
import * as ReactRotatingText from 'react-rotating-text'

const Code = (props) => {

    const [coutContent, setCoutContent] = useState("I love Italy")


    const codeText = [
        {code : "#include", style : {color : 'rgb(242,36,111)'} },
        {code: "<bits/stdc++.h>", style :{color : 'rgb(231,219,116)'}},
        {code: (<br />), style : {}},
        {code : "using namespace", style : {color : 'rgb(242,36,111)'} },
        {code : "std;", style : {color : 'rgb(248,248,242)'} },
        {code: (<br />), style: {}},
        {code: (<br />), style :{}},
        {code : "int", style:{color : 'rgb(103,216,239)'} },
        {code : "main", style:{color : 'rgb(166,226,44)'} },
        {code : "(", style:{color : 'rgb(248,248,242)'} },
        {code : "int", style:{color : 'rgb(103,216,239)'} },
        {code : "argc,", style:{color : 'rgb(248,248,242)'} },
        {code : "char", style:{color : 'rgb(103,216,239)'} },
        {code : "const", style: {color : 'rgb(242,36,111)'} },
        {code : "*argv[] )", style:{color : 'rgb(248,248,242)'} },
        {code: (<br />), style :{}},
        {code : "{", style:{color : 'rgb(248,248,242)'} },
        {code: (<br />), style :{}},
        {code : "printf", style:{color : 'rgb(103,216,239)'} },
        {code : "(", style:{color : 'rgb(248,248,242)'} },
        {code: '"', style :{color : 'rgb(231,219,116)'}},
        {code: '%s\\n', style :{color : 'rgb(172,128,255)'}},
        {code: '"', style :{color : 'rgb(231,219,116)'}},
        {code : ",", style:{color : 'rgb(248,248,242)'} },
        {code: (<ReactRotatingText 
            style={{ color : 'rgb(231,219,116)' }}
            items={['"In Search of Love."', '"I turn Ideas into Products."', '"Developer."', '"Believe In Connecting The Dots."']}
            typingInterval={90}
             />), style :{color : 'rgb(231,219,116)'}},
        {code : ");", style:{color : 'rgb(248,248,242)'} },
        {code: (<br />), style :{}},
        {code: (<br />), style :{}},
        {code : "}", style:{color : 'rgb(248,248,242)'} },
            
    ]
    return (
        <div className="code-container">
        {codeText.map((item, index) => {
            return (
                <span className="code-content" key={index} style={item.style}>
                    {item.code}
                </span>
            )
        })}
        </div>
    )

}


export default Code
